import react, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBalance } from '../redux/user.slice';
import {
  setJackpots,
  setRecentWinners,
  setRecentWithdraws,
} from '../redux/recent.slice';
import signalRSingleton from '../singletons/signalR.singleton';

function SocketWrapper() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user);

  const userHubConnected = useRef(false);
  const slotHubConnected = useRef(false);

  function watchUserHubEvents() {
    if (signalRSingleton.userHubConnected) {
      signalRSingleton.userHubConnection.on('Balance', (message) => {
        dispatch(setBalance(message));
      });
    }
  }

  function watchSlotHubEvents() {
    if (signalRSingleton.slotHubConnected) {
      signalRSingleton.slotHubConnection.on(
        'RecentWinnersResult',
        (message) => {
          if (message && message.length) {
            dispatch(setRecentWinners(message));
          }
        }
      );

      signalRSingleton.slotHubConnection.on(
        'RecentWithdrawResult',
        (message) => {
          if (message && message.length) {
            dispatch(setRecentWithdraws(message));
          }
        }
      );

      signalRSingleton.slotHubConnection.on('Jackpot', (message) => {
        dispatch(setJackpots(message));
      });
    }
  }

  async function connectToUserHub() {
    try {
      await signalRSingleton.connectToUserHub();
      watchUserHubEvents();
    } catch (e) {
      console.error('UserHub Connection Error: ', e);
    }
  }

  async function connectToSlotHub() {
    try {
      await signalRSingleton.connectToSlotHub();
      watchSlotHubEvents();
    } catch (e) {
      console.error('SlotHub Connection Error: ', e);
    }
  }

  useEffect(() => {
    if (id && !userHubConnected.current) {
      connectToUserHub();
      userHubConnected.current = true;
    } else if (!id && userHubConnected.current) {
      signalRSingleton.disconnectFromUserHub();
      userHubConnected.current = false;
    }
  }, [id, userHubConnected.current]);

  useEffect(() => {
    if (!slotHubConnected.current) {
      connectToSlotHub();
      slotHubConnected.current = true;
    }
  }, []);

  return <></>;
}

export default react.memo(SocketWrapper);
